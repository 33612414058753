import React, { useState } from "react";
import "./ourPartners.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import Carousel from "react-bootstrap/Carousel";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function OurPartnersCarousel() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  return (
    <>
      <div className="mainbg-div">
        <h2>Our Partners</h2>
        <Carousel activeIndex={index} onSelect={handleSelect}>
          <Carousel.Item>
            <div className="partners-bg">
              <Row className="partner-row">
                <Col md={9} lg={7} sm={10}  className="partner-col">
                  <Row className="inner-row">
                    <Col md={3} className="cirleimage">
                      <img src="neutroline.png" alt="people smiling"></img>
                      <p 
    style={{
      fontWeight: "bold", 
      textAlign: "center", 
      marginTop: "5px", 
      fontSize: "1.5rem", 
      color: "#333"
    }}
  >
    Neutroline
  </p>
                    </Col>
                    
                    <Col md={8} className="textparagraph">
                      <p style={{
                        marginTop:"2px",
                      
                      }}>
                      Neutroline is a Nepal-based IT company offering services such as software
                      development and testing to clients worldwide, with a primary focus on the USA, Finland,
                      India, and Nepal. Neutroline collaborates with businesses of all types and sizes. It also
                      specializes in developing and launching innovative products and software solutions.
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Carousel.Item>
          <Carousel.Item>
          <div className="partners-bg">
              <Row className="partner-row">
                <Col md={9} lg={7} sm={10}  className="partner-col">
                  <Row className="inner-row">
                    <Col md={3} className="cirleimage">
                      <img src="kimbutech.png" alt="people smiling"></img>
                      <p 
    style={{
      fontWeight: "bold", 
      textAlign: "center", 
      marginTop: "2px", 
      fontSize: "1.5rem", 
      color: "#333"
    }}
  >
    Kimbutech
  </p>
                    </Col>
                    <Col md={8} className="textparagraph">
                      <p style={{
                        marginTop:"2px",
                      
                      }}>
                      KimbuTech is an international technology company specializing in IT solutions, software
                      development, and outsourcing services. Headquartered in Nepal, KimbuTech serves
clients globally, with a strong focus on the United States, Israel, and Nepal. The
company is part of the KMC Educational Network, one of Nepal&#39;s largest and most
prestigious educational institutions.
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Carousel.Item>
          {/* <Carousel.Item>
            <div className="partners-bg ">
            <Row className="partner-row">
                <Col md={9} lg={7} sm={10}  className="partner-col">
                  <Row className="inner-row">
                    <Col md={3} className="cirleimage">
                      <img src="partner3.jpg" alt="people smiling"></img>
                    </Col>
                    <Col md={8} className="textparagraph">
                      <p>
                        Neutrosys is an IT staffing and software development
                        company. We are currently providing IT services mainly
                        in the US and Nepal. We provide services from USA,
                        Nepal, India and Finland.Neutrosys is an IT staffing and
                        software development company. We are currently providing
                        IT services mainly in the US and Nepal. We provide
                        services from USA, Nepal, India and Finland.
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Carousel.Item> */}
        </Carousel>
      </div>
    </>
  );
}
