import React, { useRef, useState } from "react";
import "./resume.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { Container, FloatingLabel, Form, Modal } from "react-bootstrap";
import { Country, State, City } from "country-state-city";
import { Box, Typography } from '@mui/material';
import PhoneInput from "react-phone-input-2";
import { useDropzone } from 'react-dropzone';
import "react-phone-input-2/lib/style.css";
import swal from "sweetalert";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// import { useForm } from "react-hook-form";
// import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import UploadFiles from "../jobSection/UploadFiles";
// import ReactToPrint from 'react-to-print';
import Select from "react-select";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useForm, Controller } from "react-hook-form";
import {
  CountryDropdown,
  RegionDropdown,
  CountryRegionData,
} from "react-country-region-selector";

export default function Resume(props) {
  const wrapperRef = useRef(null);
  const [fileList, setFileList] = useState([]);
  const [mobile, setMobile] = useState("+977");
  const [state, setState] = useState(false);
  const countries = Country.getAllCountries();
  const [show, setShow] = useState(false);
  const [base64DataCV, setBase64DataCV] = useState("");
  const [base64DataResume, setBase64DataResume] = useState("");
  const [resumeUploaded, setResumeUploaded] = useState(false); 
  const [resumeError, setResumeError] = useState(""); 
  const [isCoverLetterUploaded, setIsCoverLetterUploaded] = useState(false);
  const [isResumeUploaded, setIsResumeUploaded] = useState(false);
  const [uploadedCoverLetter, setUploadedCoverLetter] = useState(null);
  const [uploadedResume, setUploadedResume] = useState(null);
  const coverLetterRef = useRef();
  const resumeLetterRef =useRef();


  const Componentref = useRef();
  // const setCou=countries.map(item=>item.isoCode)

  const states = State.getAllStates().filter(
    (item) => item.countryCode === "IN"
  );

  // const navigate = useNavigate();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const {
    register,
    handleSubmit,
    reset,
    control,
    resetField,
    formState: { errors },
  } = useForm();
  // const onSubmit = (data) => {
  //   console.log(data);
  //   console.log('ok bro form bhariyo');
  //   reset();
  //   resetField();
  //   swal("Form has been Submitted");
  // };
  const [loading, setLoading] = useState(false);
  const onSubmit = async (data) => {
    if (!resumeUploaded) {
      setResumeError("Resume is mandatory."); // Set error message
      return; // Prevent form submission
  }
    setLoading(true); // Start loading
   
    try {
      console.log(data);
      data.coverletter = base64DataCV;
      data.resume = base64DataResume;
  
      // Fetch the base URL from the environment variable
      const baseURL = process.env.REACT_APP_API_URL;
      console.log("Base URL:", baseURL);
  
      // Send the first email
      const response = await axios.post(
        `${baseURL}/api/mailsend/confirmationafterjobapplytocandidate`,
        data
      );
      console.log("Candidate confirmation response:", response);
  
    
      const responseafterhost = await axios.post(
        `${baseURL}/api/mailsend/applytojobmailreceivedbyhost`,
        data
      );
      console.log("Host notification response:", responseafterhost);
  
     
      reset();
      // setBase64DataCV(""); // Clear cover letter
      // setBase64DataResume(""); // Clear resume
      setBase64DataCV(""); 
      setBase64DataResume(""); 
      setBase64DataCV(""); 
      setBase64DataResume(""); 
      setIsCoverLetterUploaded(false); 
      setIsResumeUploaded(false); 
      setUploadedCoverLetter(null); 
      setUploadedResume(null); 
     setResumeUploaded(false); 
      setResumeError(""); 
      

  
      toast.success("your form has been submitted");
      
    } catch (error) {
     
      console.error("Error during form submission:", error);
      swal("Something went wrong. Please try again later.", {
        icon: "error",
      });
    } finally {
      
      setLoading(false);
    }
  };
  const truncateFileName = (fileName) => {
    if (fileName.length > 15) {
      return fileName.substring(0, 15) + '...'; 
    }
    return fileName;
  };
  const onCoverLetterDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setBase64DataCV(reader.result);
        setIsCoverLetterUploaded(true);
        setUploadedCoverLetter(file.name); 
      };
      reader.readAsDataURL(file);
    }
  };

  // Drag and Drop for Resume
  const onResumeDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        // setBase64DataResume(reader.result);
        // setResumeUploaded(true); // Set resume uploaded state to true
        // setResumeError(""); // Clear any previous error message
        setBase64DataResume(reader.result);
        setIsResumeUploaded(true);
        setUploadedResume(file.name); // Set the uploaded file name
        setResumeUploaded(true); // Set resume uploaded state to true
        setResumeError(""); // Clear any previous error message
      };
      reader.readAsDataURL(file);
    }
  };
  const { getRootProps: getCoverLetterRootProps, getInputProps: getCoverLetterInputProps } = useDropzone({
    onDrop: onCoverLetterDrop,
    accept: 'image/*,application/pdf', // Acceptable file types
  });

  // Using useDropzone for Resume
  const { getRootProps: getResumeRootProps, getInputProps: getResumeInputProps } = useDropzone({
    onDrop: onResumeDrop,
    accept: 'image/*,application/pdf', // Acceptable file types
  });

  const handleCVFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setBase64DataCV(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleResumeFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setBase64DataResume(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedRegion, setSelectedRegion] = useState("");

  const handleCountryChange = (val) => {
    setSelectedCountry(val);
    setSelectedRegion(""); 
  };

  const handleRegionChange = (val) => {
    setSelectedRegion(val);
  };


  const onDragEnter = () => wrapperRef.current.classList.add("dragover");

  const onDragLeave = () => wrapperRef.current.classList.remove("dragover");

  const onDrop = () => wrapperRef.current.classList.remove("dragover");

  const onFileDrop = (e) => {
    const newFile = e.target.files[0];
    if (newFile) {
      const updatedList = [...fileList, newFile];
      setFileList(updatedList);
      props.onFileChange(updatedList);
    }
  };
  const fileRemove = (file) => {
    const updatedList = [...fileList];
    updatedList.splice(fileList.indexOf(file), 1);
    setFileList(updatedList);
    props.onFileChange(updatedList);
  };

  const [country, setCountry] = useState("");
  const [region, setRegion] = useState("");
  const selectCountry = (val) => setCountry(val);
  const selectRegion = (val) => {
    setRegion(val);
  };

  const selectOption = [
    { value: "Contract Jobs", label: "Contract Jobs" },
    { value: "Internal Jobs", label: "Internal Jobs" },
    { value: "Internship", label: "Internship" },
  ];

  return (
    <>
      <div className="resume-maindiv">
      <ToastContainer position="top-center" />
        <Row className="resume-row">
          <Col className="resume-col1">
            <img src="./images/career/jobresume.jpg" alt="resume"></img>
          </Col>
          <Col className="resume-col2">
            <h2>Not finding the suitable role for you?</h2>
            <p>
              "Submit your resume and let us help you find your next career
              opportunity, including internships, consulting, or internal
              positions that match your skills and qualifications.”
            </p>

            <div className="btn-submitdiv">
              <Button size="lg" className="rbtn" onClick={handleShow}>
                Submit Resume
              </Button>

              <Modal
                show={show}
                onHide={handleClose}
                size="lg"
                className="resume-modal"
              >
                <Modal.Header closeButton className="jobs-modal-header">
                  <Modal.Title className="top-form">
                    Enter your Information
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className="resume-modal-body">
                  <form
                    className="form details-form"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <Row className="mt-1">
                      <Col sm={12} className="resume-form-col selectjob-width">
                        <Select
                          isMulti
                          name="colors"
                          placeholder="Select Job Type"
                          
                          options={selectOption}
                          className="basic-multi-select select-bg"
                          classNamePrefix="select"
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          }}
                          defaultValue={[]} 
                          {...register("jobType")} 
                        />
                      </Col>
                    </Row>
                    <Row className="mt-1">
                <Col lg={6} md={12} sm={12} className="resume-form-col fname-width">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="First Name"
                    className="input-field"
                    style={{ color: errors.name ? 'red' : 'inherit' }}
                  >
                    <Form.Control
                      type="text"
                      placeholder="First Name"
                      {...register("name", {
                        required: "Required",
                        minLength: { value: 3, message: "Minimum length is 3" },
                      })}
                      isInvalid={!!errors?.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {/* {errors.name?.message} */}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
                <Col lg={6} md={12} sm={12} className="resume-form-col mname-width">
                  <FloatingLabel
                    controlId="floatingPassword"
                    label="Middle Name (If applicable)"
                    className="input-field"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Middle Name (If applicable)"
                      {...register("middlename")}
                    />
                  </FloatingLabel>
                </Col>
              </Row>
              <Row>
                <Col lg={6} md={12} sm={12} className="resume-form-col">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Last Name"
                    className="input-field p-0"
                    style={{ color: errors.name ? 'red' : 'inherit' }}
                  >
                    <Form.Control
                      type="text"
                      placeholder="Last Name"
                      {...register("lastname", { required: "Required" })}
                      isInvalid={!!errors?.lastname}
                    />
                    <Form.Control.Feedback type="invalid">
                      {/* {errors.lastname?.message} */}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Col>

                <Col lg={6} md={12} sm={12} className="resume-form-col">
                  <Form.Select
                    aria-label="Default select example"
                    className="input-field gender-padding"
                    style={{ color: errors.name ? 'red' : 'inherit' }}
                    {...register("gender", { required: "Required" })}
                    isInvalid={!!errors?.gender}
                  >
                    <option value="">Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="unspecified">Unspecified</option>
                    <option value="undisclosed">Undisclosed</option>
                    <option value="other">Other</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {/* {errors.gender?.message} */}
                  </Form.Control.Feedback>
                </Col>
              </Row>

              <Row>
                <Col lg={6} md={12} sm={12} className="resume-form-col cc-width-div">
                  <FloatingLabel
                    controlId="floatingPassword"
                    label="Country Code"
                    style={{ color: errors.name ? 'red' : 'inherit' }}
                    
                    className="input-field"
                  >
                    <Form.Control
                      name="country_code"
                      type="number"
                      placeholder="Nepal +977"
                      {...register("country_code", { required: "Required" })}
                      isInvalid={!!errors?.country_code}
                    />
                    <Form.Control.Feedback type="invalid">
                      {/* {errors.country_code?.message} */}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Col>

                <Col lg={6} md={12} sm={12} className="resume-form-col p-width-div">
                  <FloatingLabel
                    controlId="floatingPassword"
                    label="Phone Number"
                    className="input-field"
                    style={{ color: errors.name ? 'red' : 'inherit' }}
                  >
                    <Form.Control
                      type="number"
                      name="phone"
                      placeholder="Phone Number"
                      {...register("phone", { required: "Required" })}
                      isInvalid={!!errors?.phone}
                    />
                    <Form.Control.Feedback type="invalid">
                      {/* {errors.phone?.message} */}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
              </Row>

              <Row>
                <Col lg={6} md={12} sm={12} className="resume-form-col mb-2 country-width">
                  <Controller
                    name ="countryname"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <CountryDropdown
                        {...field}
                        className="input-field select"
                        // style={{ color: "#000000d4", height: "3.7rem" }}
                        style={{
                          color: errors.name ? 'red' : '#000000d4', 
                          height: "3.7rem"
                        }}
                        onChange={(val) => {
                          field.onChange(val);
                          handleCountryChange(val);
                        }}
                      />
                    )}
                  />
                </Col>

                <Col lg={6} md={12} sm={12} className="resume-form-col mb-2 region-width">
                  <Controller
                    name="regionname"
                    control={control}
                    
                    defaultValue=""
                    render={({ field }) => (
                      <RegionDropdown
                        {...field}
                        className="input-field select"
                        country={selectedCountry}
                        value={selectedRegion}
                        // style={{ color: "#000000d4", height: "3.7rem" }}
                        style={{
                          color: errors.name ? 'red' : '#000000d4', // Conditional color
                          height: "3.7rem"
                        }}
                        blankOptionLabel="Select Region"
                        
                        onChange={(val) => {
                          field.onChange(val);
                          handleRegionChange(val);
                        }}
                      />
                    )}
                  />
                </Col>
              </Row>

              <Row>
                <Col lg={6} md={12} sm={12} className="resume-form-col">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="City"
                    className="input-field"
                    style={{ color: errors.name ? 'red' : 'inherit' }}
                  >
                    <Form.Control
                      type="text"
                      {...register("city", { required: "Required field" })}
                      placeholder="City"
                      isInvalid={!!errors?.city}
                    />
                    <Form.Control.Feedback type="invalid">
                      {/* {errors.city?.message} */}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Col>

                <Col lg={6} md={12} sm={12} className="resume-form-col">
                  <FloatingLabel
                    controlId="floatingPassword"
                    label="Current Address"
                    className="input-field"
                    style={{ color: errors.name ? 'red' : 'inherit' }}
                  >
                    <Form.Control
                      type="text"
                      {...register("currentaddress", { required: "Required field" })}
                      placeholder="Current Address"
                      isInvalid={!!errors?.currentaddress}
                    />
                    <Form.Control.Feedback type="invalid">
                      {/* {errors.currentaddress?.message} */}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
              </Row>

              <Row>
                <Col lg={6} md={12} sm={12} className="resume-form-col email-width">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Email"
                    className="input-field"
                    style={{ color: errors.name ? 'red' : 'inherit' }}
                  >
                    <Form.Control
                      type="email"
                      placeholder="name@example.com"
                      {...register("email", {
                        required: "Required field",
                        pattern: {
                          value: /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i,
                          message: "Invalid email address",
                        },
                      })}
                      isInvalid={!!errors?.email}
                    />
                    <Form.Control.Feedback type="invalid">
                      {/* {errors.email?.message} */}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Col>

                <Col lg={6} md={12} sm={12} className="resume-form-col salary-width">
                  <FloatingLabel
                    controlId="floatingPassword"
                    label="Expected Salary (Optional)"
                    className="input-field number-arrow label-light"
                    
                  >
                    <Form.Control
                      type="number"
                      {...register("expectedsalary")}
                    />
                  </FloatingLabel>
                </Col>
              </Row>

              <Row>
                <Col lg={6} md={12} sm={12} className="resume-form-col level-width">
                  <Controller
                    name="jobappliedfor"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <Form.Select
                        aria-label="Default select example"
                        className="mb-3 input-field"
                        style={{ color: errors.name ? 'red' : 'inherit' }}
                        {...field}
                      >
                        <option value="">Applying for Seniority Level of</option>
                        <option value="Not Applicable">Not Applicable</option>
                        <option value="Internship">Internship</option>
                        <option value="Entry Level">Entry Level</option>
                        <option value="Associate(Junior)">Associate(Junior)</option>
                        <option value="Mid Level">Mid Level</option>
                        <option value="Senior">Senior</option>
                        <option value="Director">Director</option>
                        <option value="Executive">Executive</option>
                      </Form.Select>
                    )}
                  />
                </Col>

                {/* <Col lg={6} md={12} sm={12} className="resume-form-col upload-width">
                  <div className="input-field">
                    <label
                      html For="inputcover"
                      style={{
                        float: "left",
                        display: "inline-block",
                        padding: "10px 20px",
                        backgroundColor: "#007bff",
                        color: "#fff",
                        cursor: "pointer",
                        borderRadius: "5px",
                        textAlign: "center",
                      }}
                    >
                      Upload Cover Letter (Optional)
                    </label>
                    <input
                      type="file"
                      id="inputcover"
                      accept=".jpg, .jpeg, .png, .pdf"
                      onChange={handleCVFileChange}
                      style={{ display: "none" }}
                    />
                  </div>

                </Col> */}
                {/* <Col lg={6} md={12} sm={12} className="resume-form-col second-upload">
  <Box
    sx={{
      border: '1px solid  rgba(128, 128, 128, 0.521);',
      borderRadius: '8px',
      padding: '10px',
      textAlign: 'center',
      marginBottom: '1rem',
      backgroundColor: base64DataCV ? '#e3f2fd' : '#fff',
      transition: 'background-color 0.3s',
      height: '75px', 
    }}
  >
    <input
      id="inputCoverLetter"
      type="file"
      accept=".jpg, .jpeg, .png, .pdf"
      onChange={handleCVFileChange}
      style={{ display: 'none' }} // Hide the default file input
    />
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        cursor: 'pointer',
        height: '100%', // Fill the height of the parent Box
        justifyContent: 'center', // Center the content vertically
      }}
      onClick={() => document.getElementById('inputCoverLetter').click()} // Trigger file input click
    >
      <Typography variant="body2" color="textSecondary">
        {base64DataCV ? 'Cover Letter Uploaded' : 'Upload Cover Letter'}
      </Typography>
      <Button
        variant="contained"
        component="span"
        sx={{
          marginTop: '5px',
          backgroundColor: '#3b6da7',
          color: 'white',
          '&:hover': {
            backgroundColor: '#2a5d8b',
          },
          fontSize: '0.8rem', // Smaller button
        }}
      >
        Upload
      </Button>
    </Box>
  </Box>
</Col> */}
{/* <Col lg={6} md={12} sm={12}>
  <Box
    sx={{
      border: '2px dashed rgba(128, 128, 128, 0.521)',
      borderRadius: '8px',
      padding: '20px',
      textAlign: 'center',
      marginBottom: '1rem',
      backgroundColor: base64DataCV ? '#e3f2fd' : '#fff',
      transition: 'background-color 0.3s, border-color 0.3s',
      height: '100px',
      cursor: 'pointer',
      '&:hover': {
        borderColor: '#3b6da7',
        backgroundColor: '#f0f8ff', // Light background on hover
      },
    }}
    {...getCoverLetterRootProps()} // Use getCoverLetterRootProps here
    onClick={() => document.getElementById('inputCoverLetter').click()} // Trigger file input click on Box click
  >
    <input {...getCoverLetterInputProps()} id="inputCoverLetter" style={{ display: 'none' }} />
    <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
      {base64DataCV ? 'Cover Letter Uploaded' : 'Drag & Drop or Click to Upload'}
    </Typography>
    <Button
      variant="contained"
      sx={{
        backgroundColor: '#3b6da7',
        color: 'white',
        '&:hover': {
          backgroundColor: '#2a5d8b',
        },
        padding: '10px 20px',
        fontWeight: 'bold',
        borderRadius: '5px',
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
      }}
    >
      Upload Cover Letter
    </Button>
  </Box>
</Col> */}
<Col lg={6} md={12} sm={12} className="resume-form-col upload-section">
  <Box
    sx={{
      border: '2px dashed rgba(128, 128, 128, 0.521)',
      borderRadius: '8px',
      padding: '10px 15px',
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      // backgroundColor: isCoverLetterUploaded ? '#e3f2fd' : '#f9f9f9',
      // transition: 'background-color 0.3s, border-color 0.3s',
      cursor: 'pointer',
      position: 'relative', // Required for positioning the cross button
      // '&:hover': {
      //   borderColor: '#3b6da7',
      //   backgroundColor: '#f0f8ff',
      // },
    }}
    {...getCoverLetterRootProps()}
    onClick={() => document.getElementById('inputCoverLetter').click()}
  >
    <input {...getCoverLetterInputProps()} id="inputCoverLetter" style={{ display: 'none' }} />
    {isCoverLetterUploaded && (
      <span
        onClick={(e) => {
          e.stopPropagation();
          setBase64DataCV('');
          setIsCoverLetterUploaded(false);
          setUploadedCoverLetter(null);
          document.getElementById('inputCoverLetter').value = null;
        }}
        style={{
          position: 'absolute',
          top: '-10px', // Position above the border
          right: '-10px', // Position outside the border
          backgroundColor: '#ff6666',
          borderRadius: '50%',
          width: '20px',
          height: '20px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: 'white',
          fontSize: '12px',
          cursor: 'pointer',
          boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)',
        }}
      >
        &times; {/* Cross Icon */}
      </span>
    )}
    <Button
      className="coverbutton"
      variant="contained"
      sx={{
        backgroundColor: '#3b6da7',
        color: 'white',
        textTransform: 'none',
        fontWeight: 'bold',
        padding: '8px 20px',
        borderRadius: '5px',
        fontSize: '14px',
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        // '&:hover': {
        //   backgroundColor: '#2a5d8b',
        // },
      }}
    >
      {isCoverLetterUploaded ? 'Uploaded' : 'Upload Cover Letter'}
      {isCoverLetterUploaded && (
        <CheckCircleIcon
          sx={{
            fontSize: '20px',
            color: 'green',
            marginLeft: '8px',
          }}
        />
      )}
    </Button>
    <Typography
      variant="body2"
      color="textSecondary"
      sx={{ fontSize: '14px', opacity: 0.5 }}
    >
      {isCoverLetterUploaded ? truncateFileName(uploadedCoverLetter) : 'or drag them'}
    </Typography>
  </Box>
</Col>


              </Row>

              <Row className="reverse-col">
                <Col lg={6} md={12} sm={12} className="resume-form-col msgtxt-width">
                  <FloatingLabel
                    controlId="floatingTextarea2"
                    label="Message (Optional)"
                    className="input-field message-padding label-light"
                  >
                    <Form.Control
                      as="textarea"
                      {...register("message")}
                      placeholder="Leave a comment here"
                      style={{ height: "100px" }}
                    />
                  </FloatingLabel>
                </Col>

                {/* <Col lg={6} md={12} sm={12} className="resume-form-col second-upload">
                  <label
                    htmlFor="inputResume"
                    style={{
                      float: "left",
                      display: "inline-block",
                      padding: "10px 20px",
                      backgroundColor: "#007bff",
                      color: "#fff",
                      cursor: "pointer",
                      borderRadius: "5px",
                      textAlign: "center",
                    }}
                  >
                    Upload Resume Letter
                  </label>
                  <input
                    required
                    type="file"
                    id="inputResume"
                    accept=".jpg, .jpeg, .png, .pdf"
                    onChange={handleResumeFileChange}
                    style={{ display: "none" }}
                  />
                </Col> */}
                {/* <Col lg={6} md={12} sm={12} className="resume-form-col second-upload">
  <Box
    sx={{
      border: '2px solid  rgba(128, 128, 128, 0.521); ',
      borderRadius: '8px',
      padding: '10px',
      textAlign: 'center',
      marginBottom: '1rem',
      backgroundColor: base64DataResume ? '#e3f2fd' : '#fff',
      transition: 'background-color 0.3s',
      height: '75px',
       
    }}
  >
    <input
      id="inputResume"
      type="file"
      accept=".jpg, .jpeg, .png, .pdf"
      onChange={handleResumeFileChange}
      style={{ display: 'none' }} // Hide the default file input
    />
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        cursor: 'pointer',
        height: '100%', // Fill the height of the parent Box
        justifyContent: 'center', // Center the content vertically
      }}
      onClick={() => document.getElementById('inputResume').click()} // Trigger file input click
    >
      <Typography variant="body2" color="textSecondary">
        {base64DataResume ? 'Resume Uploaded' : 'Upload Resume'}
      </Typography>
      <Button
        variant="contained"
        component="span"
        sx={{
          marginTop: '5px',
          backgroundColor: '#3b6da7',
          color: 'white',
          '&:hover': {
            backgroundColor: '#2a5d8b',
          },
          fontSize: '0.8rem', // Smaller button
        }}
      >
        Upload
      </Button>
    </Box>
  </Box>
</Col> */}

<Col lg={6} md={12} sm={12} className="resume-form-col">
  <Box
    sx={{
      border: '2px dashed #ccc',
      borderRadius: '8px',
      padding: '10px 15px',
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      textAlign: 'center',
      // backgroundColor: isResumeUploaded ? '#e3f2fd' : '#f9f9f9',
      // transition: 'background-color 0.3s, border-color 0.3s',
      cursor: 'pointer',
      position: 'relative', 
      // '&:hover': {
      //   borderColor: '#3b6da7',
      //   backgroundColor: '#f0f8ff',
      // },
    }}
    {...getResumeRootProps()}
    onClick={() => document.getElementById('inputResume').click()}
  >
    <input {...getResumeInputProps()} id="inputResume" style={{ display: 'none' }} />
    <Button
      className="resumebutton"
      variant="contained"
      sx={{
        backgroundColor: '#6c63ff', // Purple background
        color: '#fff', // White text
        textTransform: 'none', // Normal text case
        fontWeight: 'bold',
        padding: '8px 20px', // Padding for compact button
        borderRadius: '5px', // Slightly rounded corners
        fontSize: '14px', // Adjusted font size
        // '&:hover': {
        //   backgroundColor: '#574fcf', // Darker purple on hover
        // },
      }}
    >
      {isResumeUploaded ? 'Uploaded' : 'Upload Resume/CV'}
      {isResumeUploaded && (
        <CheckCircleIcon
          sx={{
            fontSize: '20px',
            color: 'green',
            marginLeft: '8px',
          }}
        />
      )}
    </Button>
    <Typography
      variant="body2"
      color="textSecondary"
      className="dark-text"
      sx={{ fontSize: '14px' }}
    >
      {isResumeUploaded ? truncateFileName(uploadedResume) : 'or drag them'}
    </Typography>
    {isResumeUploaded && (
      <span
        onClick={(e) => {
          e.stopPropagation();
          setBase64DataResume('');
          setIsResumeUploaded(false);
          setUploadedResume(null);
          document.getElementById('inputResume').value = null;

        }}
        style={{
          position: 'absolute',
          top: '-10px', 
          right: '-10px', 
          backgroundColor: '#ff6666',
          borderRadius: '50%',
          width: '20px',
          height: '20px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: 'white',
          fontSize: '12px',
          cursor: 'pointer',
          boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)',
        }}
      >
        &times; {/* Cross Icon */}
      </span>
    )}
  </Box>
  {resumeError && (
    <Typography
      variant="caption"
      color="error"
      sx={{ display: 'block', marginTop: '8px' }}
    >
      {resumeError}
    </Typography>
  )}
</Col>


              </Row>

              <Row>
                <Col lg={9} md={9} sm={12} className="check-container-col">
                  <div className="check-container">
                    <input type="checkbox" name="check" id="applycheck" required />
                    <p className="check">
                      By checking this box, I certify that the information submitted in this application is true and correct to the best of my knowledge.
                    </p>
                  </div>
                </Col>


                      <Col lg={3} md={3} sm={12} className="Rsubmit-btn-col">
                        {/* <Button variant="success" id="submit-btn" type="submit">
                          Submit
                        </Button>{" "} */}
                         <Button
              style={{
                display: "block",
                margin: "auto",
                width: "7rem",
                color: "white",
                marginTop:"8px",
                backgroundColor: "#3b6da7",
              }}
              type="submit"
              variant="contained"
              disabled={loading} // Disable button when loading
            >
              {loading ? (
                <div className="spinner-border text-light" role="status" style={{ fontSize: "1.2rem" }}>
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                "Submit"
              )}
            </Button>

                      </Col>
                    </Row>
                  </form>
                  {/* </Form> */}
                </Modal.Body>
              </Modal>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}