
import { TextField, Button, Box, Typography } from "@mui/material";
import './FormMui.css';
// import { useEffect } from "react";
import { useState,useRef,useEffect } from "react";
import { Container } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { Multiselect } from "multiselect-react-dropdown"; 
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import checkboxClasses from "@mui/material/Checkbox";
import { green } from "@mui/material/colors";
import { style } from "@mui/system";
import axios from "axios";
import { useDropzone } from "react-dropzone";
import { pink } from '@mui/material/colors';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
// import Switch from '@mui/material/Switch';
export default function FormWithMui() {
  const[tick,setTick]=useState(false)
  const [state, setstate] = useState("white")
  const nameRef = useRef();
  const emailRef = useRef();
  const countryCodeRef = useRef();
  const phoneRef = useRef();
  const locationRef = useRef();
  const subjectRef = useRef();
  const messageRef = useRef();
 
  const clickme=(id)=>{
  //   setTick(!tick);
  //  if(tick){
  //   setstate("white")
  
  //  }else{
  //   setstate("pink")
    
  //  }
 }

 const [options, setOptions] = useState([
  "Talent Solutions",
  "Out Sourcing",
  "Software Testing",
  "Software QA",
]);
 
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const {
    register,
    handleSubmit,
    reset,
    resetField,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState(false);
  const [componentKey, setComponentKey] = useState(0); 
  const [toastMessage, setToastMessage] = useState(""); 
 const [showToast, setShowToast] = useState(false); 
   const fileInputRef = useRef(null);
  const onSubmit = async(data) => {
    setLoading(true);
    try{ console.log(data);
      console.log("base64 data image",base64Data);
      console.log(data);
      data.image = base64Data;
  
      // console.log(file);
      // data.image = file;
  
      // Fetch the base URL from the environment variable
      const baseURL = process.env.REACT_APP_API_URL;
      
  
      console.log("after adding image data",data);
      const responseforhost = await axios.post(`${baseURL}/api/mailsend/contactustohost`,data)
      console.log(responseforhost);
      const responseforuser = await axios.post(`${baseURL}/api/mailsend/contactussendtouser`,data)
      console.log(responseforuser);
      reset();
      resetField();
      setToastMessage("Success! Your form has been submitted.");
      setShowToast(true); // Trigger toast visibility
      
      setSelectedFile(null); // Clear the selected file state
      if (fileInputRef.current) {
        fileInputRef.current.value = ""; // Clear the file input
      }
  
      // Update component key to force re-render
      setComponentKey(prevKey => prevKey + 1); 
      // toast.success("your form has been submitted");

    }
    catch (error) {
      console.error("Error submitting form:", error);
      if (errors.name) {
        nameRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
      } else if (errors.email) {
        emailRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
      } else if (errors.country_code) {
        countryCodeRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
      } else if (errors.phone) {
        phoneRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
      } else if (errors.location) {
        locationRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
      } else if (errors.subject) {
        subjectRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
      } else if (errors.message) {
        messageRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
      }

      toast.error("Submission failed. Please try again.");

    } finally {
      setLoading(false); // Stop loading
    }
    
   
    
  };
  const trimFileName = (fileName) => {
    if (fileName.length <= 15) {
      return fileName; 
    }
    return `${fileName.substring(0, 12)}...`; 
  };

  useEffect(() => {
    if (showToast) {
      toast.success(toastMessage); // Show the toast
      setShowToast(false); // Reset the state to prevent it from showing again
    }
  }, [showToast, toastMessage]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [base64Data, setBase64Data] = useState('');
  const [uploadStatus, setUploadStatus] = useState('');
  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setBase64Data(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document', // Acceptable file types
  });

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        // const base64 = reader.result.split(',')[1]; // Get the base64 data (omit the "data:image/jpeg;base64," prefix)
        const base64 = reader.result;
        console.log(base64);
        setBase64Data(base64);
        setSelectedFile(file);
      };
      reader.readAsDataURL(file);
      setSelectedFile(file);
    }
  };
  return (
    <>
       <ToastContainer position="top-center" />
      <form key={componentKey}  className="form_container" onSubmit={handleSubmit(onSubmit)}>
        <Row className="form-row mb-4">
          <Col sm={9} className="contactus-form-col ">
            <h4 className="heading-form">Contact Us</h4>            
            <Container className="mt-2 mb-1 formContainer">
              <TextField
                // required
                fullWidth
                label="Full Name"
                margin="dense"
                name="name"
                className="textfield"
                variant="filled"
                placeholder="Your Name"
                autoComplete="off"
                sx={{
                  "& .MuiFilledInput-underline:before": {
                    borderBottom: "none",
                  },
                  "& .MuiFilledInput-underline:after": {
                    borderBottom: "none",
                  },
                  "& .MuiFilledInput-underline:hover:not(.Mui-disabled):before": {
                    borderBottom: "none",
                  },
                }}
                {...register("name", { required: "Required", minLength: 3 })}
                error={!!errors?.name}
               
              />

              <TextField
                // required
                fullWidth
                label="Email"
                margin="dense"
                name="email"
                className="textfield"
                variant="filled"
                placeholder="Your Email"
                autoComplete="off"
                sx={{
                  "& .MuiFilledInput-underline:before": {
                    borderBottom: "none",
                  },
                  "& .MuiFilledInput-underline:after": {
                    borderBottom: "none",
                  },
                  "& .MuiFilledInput-underline:hover:not(.Mui-disabled):before": {
                    borderBottom: "none",
                  },
                }}

                {...register("email", {
                  required: "Required field",
                  pattern: {
                    value: /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i,
                    message: "Invalid email address",
                  },
                })}
                error={!!errors?.email}
               
              />
              <div className="tel">
                <TextField
                
                  // fullWidth
                  type="number"
                  placeholder="USA +1"
                  label="Country Code"
                  margin="dense"
                  name="country_code"
                  autoComplete="off"
                  variant="filled"
                  sx={{
                    "& .MuiFilledInput-underline:before": {
                      borderBottom: "none",
                    },
                    "& .MuiFilledInput-underline:after": {
                      borderBottom: "none",
                    },
                    "& .MuiFilledInput-underline:hover:not(.Mui-disabled):before": {
                      borderBottom: "none",
                    },
                  }}
                  className="txtfield_country_code"
                  {...register("country_code", { required: "Required" })}
                  error={!!errors?.country_code}
                />
                <TextField
                  // inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}

                 
                  // fullWidth
                  label="Phone"
                  margin="dense"
                  type="number"
                  name="phone"
                  autoComplete="off"
                  variant="filled"
                  placeholder=" Landline/Mobile Number"
                  className="txtfield_phone "
                  sx={{
                    "& .MuiFilledInput-underline:before": {
                      borderBottom: "none",
                    },
                    "& .MuiFilledInput-underline:after": {
                      borderBottom: "none",
                    },
                    "& .MuiFilledInput-underline:hover:not(.Mui-disabled):before": {
                      borderBottom: "none",
                    },
                  }}
                  {...register("phone", { required: "Required" })}
                  error={!!errors?.phone}
                />
              </div>
              <TextField
                fullWidth
                label="Location"
                margin="dense"
                name="location"
                autoComplete="off"
                variant="filled"
                placeholder="Your Location"
                className="txtfield_country_code"
                sx={{
                  "& .MuiFilledInput-underline:before": {
                    borderBottom: "none",
                  },
                  "& .MuiFilledInput-underline:after": {
                    borderBottom: "none",
                  },
                  "& .MuiFilledInput-underline:hover:not(.Mui-disabled):before": {
                    borderBottom: "none",
                  },
                }}
                {...register("location", { required: "Required" })}
                error={!!errors?.location}
              />
              <TextField
                fullWidth
                label="Subject"
                margin="dense"
                name="subject"
                variant="filled"
                placeholder="Subject"
                className="txtfield_country_code"
                // value={myForm.values.subject}
                // onChange={myForm.handleChange}
                // error={!!myForm.errors.companyName}
                // helperText={myForm.errors.subject}

                autoComplete="off"
                sx={{
                  "& .MuiFilledInput-underline:before": {
                    borderBottom: "none",
                  },
                  "& .MuiFilledInput-underline:after": {
                    borderBottom: "none",
                  },
                  "& .MuiFilledInput-underline:hover:not(.Mui-disabled):before": {
                    borderBottom: "none",
                  },
                }}
                {...register("subject", { required: "Required" })}
                error={!!errors?.subject}
              />
              <TextField
                fullWidth
                multiline
                rows={3}
                label="Message"
                margin="dense"
                name="message"
                variant="filled"
                placeholder="Your Meassage"
                className="txtfield_country_code message_box"
                // value={myForm.values.message}
                // onChange={myForm.handleChange}
                // error={!!myForm.errors.companyName}
                // helperText={myForm.errors.message}
                autoComplete="off"
                sx={{
                  "& .MuiFilledInput-underline:before": {
                    borderBottom: "none",
                  },
                  "& .MuiFilledInput-underline:after": {
                    borderBottom: "none",
                  },
                  "& .MuiFilledInput-underline:hover:not(.Mui-disabled):before": {
                    borderBottom: "none",
                  },
                }}
                {...register("message", { required: "Required" })}
                error={!!errors?.message}
              />
              {/* <div style={{display:"flex", marginBottom:"1.5rem"}}>
              <input id="uploadfilehere" type="file" accept=".jpg, .jpeg, .png" onChange={handleFileChange} />
              <label htmlFor="upload_file">
                {/* <input
                  style={{ display: "none" }}
                  id="upload_file"
                  name="upload_file"
                  type="file"
                  // value={formValues.upload_file}
                  // value={""}
                  // onChange={handleChange}
                  accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/png, image/jpeg,.pdf"
                  {...register("upload_file")}
                /> */}
                {/* <Button
                  // color="light"
                  variant="contained"
                  component="span"
                  className="file_button mt-3"
                >
                  Upload Files
                </Button> */}
                {/* <small style={{ color: "#b1a40d" }}>Upload file </small> */}
              {/* </label>
              </div> */} 
              {/* <Box
  sx={{
    // border: '2px dashed #3b6da7',
    borderRadius: '8px',
    padding: '20px',
    textAlign: 'center',
    marginBottom: '1.5rem',
    backgroundColor: selectedFile ? '#e3f2fd' : '#fff',
    transition: 'background-color 0.3s',
  }}
>
  <input
    id="upload-file-input"
    type="file"
    accept=".jpg, .jpeg, .png, .pdf, .doc, .docx"
    onChange={handleFileChange}
    style={{ display: 'none' }} // Hide the default file input
  />
  <label htmlFor="upload-file-input">
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        cursor: 'pointer',
      }}
    >
      <Typography variant="body1" color="textSecondary">
        {selectedFile ? selectedFile.name : "Upload File"}
      </Typography>
      <Button
        variant="contained"
        component="span"
        sx={{
          marginTop: '10px',
          backgroundColor: '#3b6da7',
          color: 'white',
          '&:hover': {
            backgroundColor: '#2a5d8b',
          },
        }}
      >
        Upload File
      </Button>
    </Box>
  </label>
</Box> */}
{/* <Box
                {...getRootProps()}
                sx={{
                  border: '2px dashed rgba(128, 128, 128, 0.521)',
                  borderRadius: '8px',
                  padding: '20px',
                  textAlign: 'center',
                  marginBottom: '1rem',
                  backgroundColor: base64Data ? '#e3f2fd' : '#fff',
                  cursor: 'pointer',
                  '&:hover': {
                    borderColor: '#3b6da7',
                    backgroundColor: '#f0f8ff',
                  },
                }}
              >
                <input {...getInputProps()} />
                <Typography variant="body2" color="textSecondary">
                  {base64Data ? 'File Uploaded' : 'Drag & Drop File Here or Click to Upload'}
                </ Typography>
                <Button
                  variant="contained"
                  sx={{
                    marginTop: '10px',
                    backgroundColor: '#3b6da7',
                    color: 'white',
                    '&:hover': {
                      backgroundColor: '#2a5d8b',
                    },
                  }}
                >
                  Upload File
                </Button>
              </Box> */}
              {/* <Button
                style={{ display: "block", margin: "auto", width: "7rem", color:"white",backgroundColor: "#3b6da7",  }}
                // disabled={!myForm.isValid}
                // onClick={myForm.submitForm}
                type="submit"
                variant="contained"
                
              >
                Submit
              </Button> */}
               <div style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
  <input
    id="upload-file-input"
    type="file"
    accept=".jpg, .jpeg, .png, .pdf, .doc, .docx"
    onChange={handleFileChange}
    ref={fileInputRef}
    style={{ display: 'none' }} 
  />
  <label htmlFor="upload-file-input">
    <Button
      variant="outlined" 
      component="span"
  
      sx={{
        color: 'black', 
        backgroundColor: selectedFile ? '#d3d3d3' : 'white',
        border: '2px solid rgba(128, 128, 128, 0.521)', 
        marginTop: '16px',
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
        borderRadius: '8px',
        paddingRight: '40px', 
        position: 'relative', 
        '&:hover': {
          backgroundColor: '#f0f0f0', 
          color: 'black', 
        },
      }}
    >
      {selectedFile ? trimFileName(selectedFile.name) : 'Upload File'}
      {selectedFile && (
        <span
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setSelectedFile(null); // Clear the selected file
            setBase64Data(''); // Clear the base64 data
            if (fileInputRef.current) {
              fileInputRef.current.value = ""; // Clear the file input
            }
          }}
          style={{
            position: 'absolute',
            top: '-10px', // Position above the border
            right: '-10px', // Position outside the border
            backgroundColor: '#ff6666',
            borderRadius: '50%',
            width: '20px',
            height: '20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'white',
            fontSize: '12px',
            cursor: 'pointer',
            boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)',
          }}
        >
          &times; {/* Cross Icon */}
        </span>
      )}
    </Button>
  </label>
</div>

            <Button className="submit-button" 
              style={{
                display: "block",
                margin: "auto",
                width: "7rem",
                color: "white",
                backgroundColor: "#3b6da7",
              }}
              type="submit"
              variant="contained"
              disabled={loading} // Disable button when loading
            >
              {loading ? (
                <div className="spinner-border text-light" role="status" style={{ fontSize: "1.2rem" }}>
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                "Submit"
              )}
            </Button>

            </Container>
          </Col>
        </Row>
      </form>
    </>
  );
}
