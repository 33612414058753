import React, { useState,useRef,useEffect } from "react";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import "./Form.css";
import { Controller, useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import { TextField, Button, Box, Typography } from "@mui/material";
import { useDropzone } from "react-dropzone";
import axios from "axios";
// import {
//   CountryDropdown,
//   RegionDropdown,
//   CountryRegionData,
// } from "react-country-region-selector";
import "react-toastify/dist/ReactToastify.css";
const FormEl = () => {
  const [file, setFile] = useState(null);
  const {
    register,
    handleSubmit,
    control,
    reset,
    resetField,
    formState: { errors },
  } = useForm();
  // console.log(errors);
  const nameRef = useRef();
  const emailRef = useRef();
  const countryCodeRef = useRef();
  const phoneRef = useRef();
  const companyNameRef =useRef();
  const jobTitleRef =useRef();
  const cityRef = useRef();

  const messageRef = useRef();
  const [loading, setLoading] = useState(false);
  const [componentKey, setComponentKey] = useState(0); 
  const [toastMessage, setToastMessage] = useState(""); // State to hold the toast message
   const [showToast, setShowToast] = useState(false); // State to control toast visibility
   const fileInputRef = useRef(null);
  // const onSubmit = async (data) => {
  //   setLoading(true); // Start loading
  //   try {
  //     console.log("base64 data image", base64Data);
  //     console.log(data);
  
  //     // Add base64 image data to the data object
  //     data.image = base64Data;
  
  //     // Fetch the base URL from the environment variable
  //     const baseURL = process.env.REACT_APP_API_URL;
  
  //     console.log("after adding image data", data);
  
  //     // Make the first API call
  //     const responseForUser = await axios.post(
  //       `${baseURL}/api/mailsend/employersrequesttalentsendtouser`,
  //       data
  //     );
  //     console.log("Response for user:", responseForUser);
  
  //     // Make the second API call
  //     const responseForHost = await axios.post(
  //       `${baseURL}/api/mailsend/employersrequestatalent`,
  //       data
  //     );
  //     console.log("Response for host:", responseForHost);
  
  //     // Notify user of successful submission
  //     toast.success("Your form has been submitted!");
  //   } catch (error) {
  //     console.error("Error submitting form:", error);
  //     if (errors.name) {
  //       nameRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
  //     } else if (errors.email) {
  //       emailRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
  //     } else if (errors.phoneNumber) {
  //       phoneRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
  //     } else if (errors.CompanyName) {
  //       companyNameRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
  //     } else if (errors.Job) {
  //       jobTitleRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
  //     } else if (errors.city) {
  //       cityRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
  //     } else if (errors.message) {
  //       messageRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
  //     }
  //     toast.error("Submission failed. Please try again.");
  //   } finally {
  //     setLoading(false); // Stop loading
  //     reset(); // Reset the form
  //   }
  // };
  const onSubmit = async (data) => {
    setLoading(true); // Start loading
    try {
      // Check for errors before proceeding
      const hasErrors = Object.keys(errors).length > 0;
      if (hasErrors) {
        if (errors.name) {
          nameRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
        } else if (errors.email) {
          emailRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
        } else if (errors.phoneNumber) {
          phoneRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
        } else if (errors.CompanyName) {
          companyNameRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
        } else if (errors.Job) {
          jobTitleRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
        } else if (errors.city) {
          cityRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
        } else if (errors.message) {
          messageRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
        }
        toast.error("Please fix the errors in the form.");
        return; // Exit early if there are errors
      }
  
      // Proceed with API calls if no errors
      console.log("base64 data image", base64Data);
      console.log(data);
      data.image = base64Data;
  
      const baseURL = process.env.REACT_APP_API_URL;
  
      // Make the first API call
      const responseForUser  = await axios.post(
        `${baseURL}/api/mailsend/employersrequesttalentsendtouser`,
        data
      );
      console.log("Response for user:", responseForUser );
  
      // Make the second API call
      const responseForHost = await axios.post(
        `${baseURL}/api/mailsend/employersrequestatalent`,
        data
      );
      console.log("Response for host:", responseForHost);
      setToastMessage("Success! Your form has been submitted.");
      setShowToast(true); // Trigger toast visibility
      
      setSelectedFile(null); // Clear the selected file state
      if (fileInputRef.current) {
        fileInputRef.current.value = ""; // Clear the file input
      }
  
      // Update component key to force re-render
      setComponentKey(prevKey => prevKey + 1); 

  
      // Notify user of successful submission
      // toast.success("Your form has been submitted!");
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("Submission failed. Please try again.");
    } finally {
      setLoading(false); // Stop loading
      reset(); // Reset the form
    }
  };
  const trimFileName = (fileName) => {
    if (fileName.length <= 15) {
      return fileName; 
    }
    return `${fileName.substring(0, 12)}...`; 
  };

  useEffect(() => {
    if (showToast) {
      toast.success(toastMessage); // Show the toast
      setShowToast(false); // Reset the state to prevent it from showing again
    }
  }, [showToast, toastMessage]);
  // const handleFileChange = (e) => {
  //   const img = {
  //     preview: URL.createObjectURL(e.target.files[0]),
  //     data: e.target.files[0],
  //   };
  //   console.log(img);
  //   setFile(img);
  // };
  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setBase64Data(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document', // Acceptable file types
  });

  const [country, setCountry] = useState("");
  const [region, setRegion] = useState("");
  const selectCountry = (val) => setCountry(val);
  const selectRegion = (val) => {
    setRegion(val);
  };
  const [selectedFile, setSelectedFile] = useState(null);
  const [base64Data, setBase64Data] = useState('');
  const [uploadStatus, setUploadStatus] = useState('');

  // const convertBase64 = (file) => {
  //   return new Promise((resolve, reject) => {
  //     const fileReader = new FileReader();
  //     fileReader.readAsDataURL(file);

  //     fileReader.onload = () => {
  //       resolve(fileReader.result);
  //     };

  //     fileReader.error = (error) => {
  //       reject(error);
  //     };
  //   });
  // };

    const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64 = reader.result;// Get the base64 data (omit the "data:image/jpeg;base64," prefix)
        console.log(base64);
        setBase64Data(base64);
      };
      reader.readAsDataURL(file);
      setSelectedFile(file);
    }
  };

  // const handleUpload = () => {
  //   console.log('yo chalyo ta?');
  //   if (base64Data) {
  //     // Make a POST request to your API with the base64 data
  //     fetch('YOUR_API_ENDPOINT', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify({ base64Data }),
  //     })
  //       .then((response) => response.json())
  //       .then((data) => {
  //         setUploadStatus('File uploaded successfully');
  //         // Handle the API response as needed
  //       })
  //       .catch((error) => {
  //         setUploadStatus('File upload failed');
  //         console.error('Error uploading file:', error);
  //       });
  //   }
  // };

  return (
    <div className="form-container">
      <div className="form-left">
        <p className="frompara">Request Talent</p>
        {/* <p className="spanclass">
          Please fill out the form with your details. We will contact you soon.
        </p> */}
        <ToastContainer position="top-center" />
        <form   key={componentKey} className="form_container" onSubmit={handleSubmit(onSubmit)}>
          <TextField
            inputRef={nameRef}
            id="outlined-basic"
            className="textfield"
            label="Full Name"
            fullWidth
            variant="filled"
            size="small"
            margin="dense"
            name="name"
            autoComplete="off"
            sx={{
              "& .MuiFilledInput-underline:before": {
                borderBottom: "none",
              },
              "& .MuiFilledInput-underline:after": {
                borderBottom: "none",
              },
              "& .MuiFilledInput-underline:hover:not(.Mui-disabled):before": {
                borderBottom: "none",
              },
            }}
            {...register("name", { required: "Required", minLength: 3 })}
            error={!!errors?.name}
          />

          <TextField
            inputRef={emailRef}
            id="outlined-basic"
            className="textfield"
            label="Company Email"
            fullWidth
            variant="filled"
            size="small"
            margin="dense"
            name="email"
            autoComplete="off"
            sx={{
              "& .MuiFilledInput-underline:before": {
                borderBottom: "none",
              },
              "& .MuiFilledInput-underline:after": {
                borderBottom: "none",
              },
              "& .MuiFilledInput-underline:hover:not(.Mui-disabled):before": {
                borderBottom: "none",
              },
            }}
            {...register("email", {
              required: "Required field",
              pattern: {
                value: /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i,
                message: "Invalid email address",
              },
            })}
            error={!!errors?.email}
          />

          <TextField
            inputRef={phoneRef}
            id="outlined-basic"
            type="number"
            label="Phone Number"
            className="textfield"
            fullWidth
            variant="filled"
            size="small"
            margin="dense"
            name="phoneNumber"
            autoComplete="off"
            sx={{
              "& .MuiFilledInput-underline:before": {
                borderBottom: "none",
              },
              "& .MuiFilledInput-underline:after": {
                borderBottom: "none",
              },
              "& .MuiFilledInput-underline:hover:not(.Mui-disabled):before": {
                borderBottom: "none",
              },
            }}
            {...register("phoneNumber", { required: "Required" })}
            error={!!errors?.phoneNumber}
          />
          <div className="wrap">
            <div className="first">
              <div className="apple">
                <TextField
                  inputRef={companyNameRef}
                  id="outlined-basic"
                  label="Company Name"
                  className="textfield"
                  variant="filled"
                  size="small"
                  margin="dense"
                  name="CompanyName"
                  autoComplete="off"
                  sx={{
                    "& .MuiFilledInput-underline:before": {
                      borderBottom: "none",
                    },
                    "& .MuiFilledInput-underline:after": {
                      borderBottom: "none",
                    },
                    "& .MuiFilledInput-underline:hover:not(.Mui-disabled):before":
                      {
                        borderBottom: "none",
                      },
                  }}
                  {...register("CompanyName", { required: "Required" })}
                  error={!!errors?.CompanyName}
                />

                <TextField
                 inputRef={jobTitleRef}
                  id="outlined-basic"
                  label="Job Title"
                  className="textfield"
                  variant="filled"
                  size="small"
                  margin="dense"
                  name="Job"
                  autoComplete="off"
                  sx={{
                    "& .MuiFilledInput-underline:before": {
                      borderBottom: "none",
                    },
                    "& .MuiFilledInput-underline:after": {
                      borderBottom: "none",
                    },
                    "& .MuiFilledInput-underline:hover:not(.Mui-disabled):before":
                      {
                        borderBottom: "none",
                      },
                  }}
                  {...register("Job", { required: "Required" })}
                  error={!!errors?.Job}
                />
              </div>
            </div>
            <div className="second">
              <div className="country">
                <TextField
                 inputRef={countryCodeRef}
                  id="outlined-basic"
                  label="Country"
                  className="textfield"
                  variant="filled"
                  size="small"
                  margin="dense"
                  name="Country"
                  autoComplete="off"
                  sx={{
                    "& .MuiFilledInput-underline:before": {
                      borderBottom: "none",
                    },
                    "& .MuiFilledInput-underline:after": {
                      borderBottom: "none",
                    },
                    "& .MuiFilledInput-underline:hover:not(.Mui-disabled):before":
                      {
                        borderBottom: "none",
                      },
                  }}
                  {...register("Country", { required: "Required" })}
                  error={!!errors?.Country}
                />
                {/* <CountryDropdown
                  value={country}
                  onChange={(val) => selectCountry(val)}
                /> */}

                <TextField
                 inputRef={cityRef}
                  id="outlined-basic"
                  label="City/State"
                  className="textfield"
                  variant="filled"
                  size="small"
                  name="city"
                  margin="dense"
                  autoComplete="off"
                  sx={{
                    "& .MuiFilledInput-underline:before": {
                      borderBottom: "none",
                    },
                    "& .MuiFilledInput-underline:after": {
                      borderBottom: "none",
                    },
                    "& .MuiFilledInput-underline:hover:not(.Mui-disabled):before":
                      {
                        borderBottom: "none",
                      },
                  }}
                  {...register("city", { required: "Required" })}
                  error={!!errors?.city}
                />
                {/* 
                <RegionDropdown
                  country={country}
                  value={region}
                  onChange={(val) => selectRegion(val)}
                /> */}
              </div>
            </div>
          </div>
          {/* <TextField
            fullWidth
            multiline
            rows={3}
            label="Message"
            margin="dense"
            name="message"
            variant="filled"
            placeholder="Your Message"
            className="txtfield_country_code"
            autoComplete="off"
            sx={{
              "& .MuiFilledInput-underline:before": {
                borderBottom: "none",
              },
              "& .MuiFilledInput-underline:after": {
                borderBottom: "none",
              },
              "& .MuiFilledInput-underline:hover:not(.Mui-disabled):before": {
                borderBottom: "none",
              },
            }}
            {...register("message")}
          /> */}

          <TextField
            fullWidth
            inputRef={messageRef}
            multiline
            rows={3}
            label="Message"
            margin="dense"
            name="message"
            variant="filled"
            style={{ background: "#FFFFFF" }}
            placeholder="Your Message"
            className="txtfield_message_box message_box"
            // value={myForm.values.message}
            // onChange={myForm.handleChange}
            // error={!!myForm.errors.companyName}
            // helperText={myForm.errors.message}
            autoComplete="off"
            sx={{
              "& .MuiFilledInput-underline:before": {
                borderBottom: "none",
              },
              "& .MuiFilledInput-underline:after": {
                borderBottom: "none",
              },
              "& .MuiFilledInput-underline:hover:not(.Mui-disabled):before": {
                borderBottom: "none",
              },
            }}
            {...register("message", { required: "Required" })}
            error={!!errors?.message}
          />
          {/* <div className="form-file">
            <input type="file" accept=".jpg, .jpeg, .png" onChange={handleFileChange} />
            <label htmlFor="upload_file">
              {/* <Controller
                name="image"
                control={control}
                defaultValue={null}
                render={({ field }) => (
                  <input
                    type="file"
                    onChange={async (e) => {
                      field.onChange(e.target.files[0]);
                      setFile(e.target.files[0]);
                      const touploadfile = e.target.files[0];
                      console.log(e.target.files[0]);
                      const base64 = await convertBase64(touploadfile);
                      console.log(base64);
                    }}
                  />
                )}
                {...register("file")}
              /> */}
              {/* <input type='file' name='upload_file' onChange={handleFileChange} {...register("upload_file")}></input> */}
              {/* <input
                style={{ display: "none" }}
                id="upload_file"
                name="upload_file"
                type="file"
             
                accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/png, image/jpeg,.pdf"
                {...register("upload_file")}
              /> */}
              {/* <Button
                variant="contained"
                component="span"
                className="file_button mt-3"
              >
                Upload Files
              </Button> */}

              {/* <small style={{ color: "#b1a40d" }}>Upload file </small> */}
            {/* </label>
          </div>  */}
          {/* <Box
  sx={{
    // border: '2px dashed #3b6da7',
    borderRadius: '8px',
    padding: '20px',
    textAlign: 'center',
    marginBottom: '1.5rem',
    backgroundColor: selectedFile ? '#e3f2fd' : '#fff',
    transition: 'background-color 0.3s',
  }}
>
  <input
    id="upload-file-input"
    type="file"
    accept=".jpg, .jpeg, .png,.pdf , .doc , .docx"
    onChange={handleFileChange}
    style={{ display: 'none' }} // Hide the default file input
  />
  <label htmlFor="upload-file-input">
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        cursor: 'pointer',
      }}
    >
      <Typography variant="body1" color="textSecondary">
        {selectedFile ? selectedFile.name : "Drag & drop your file here or click to upload"}
      </Typography>
      <Button
        variant="contained"
        component="span"
        sx={{
          marginTop: '10px',
          backgroundColor: '#3b6da7',
          color: 'white',
          '&:hover': {
            backgroundColor: '#2a5d8b',
          },
        }}
      >
        Upload File
      </Button>
    </Box>
  </label>
</Box> */}
 {/* <Box
                {...getRootProps()}
                sx={{
                  border: '2px dashed rgba(128, 128, 128, 0.521)',
                  borderRadius: '8px',
                  padding: '20px',
                  textAlign: 'center',
                  marginBottom: '1rem',
                  backgroundColor: base64Data ? '#e3f2fd' : '#f9f9f9',
                  cursor: 'pointer',
                  '&:hover': {
                    borderColor: '#3b6da7',
                    backgroundColor: '#f0f8ff',
                  },
                }}
              >
                <input {...getInputProps()} />
                <Typography variant="body2" color="black">
                  {base64Data ? 'File Uploaded' : 'Drag & Drop File Here or Click to Upload'}
                </Typography>
                <Button
                  variant="contained"
                  sx={{
                    marginTop: '10px',
                    backgroundColor: '#3b6da7',
                    color: 'white',
                    '&:hover': {
                      backgroundColor: '#2a5d8b',
                    },
                  }}
                >
                  Upload File
                </Button>
              </Box> */}
       <div style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
  <input
    id="upload-file-input"
    type="file"
    accept=".jpg, .jpeg, .png, .pdf, .doc, .docx"
    onChange={handleFileChange}
    ref={fileInputRef}
    style={{ display: 'none' }} 
  />
  <label htmlFor="upload-file-input">
    <Button
      variant="outlined" 
      component="span"
      sx={{
        color: 'black', 
        backgroundColor: selectedFile ? '#d3d3d3' : 'white',
        border: '2px solid rgba(128, 128, 128, 0.521)', 
        marginTop: '16px',
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
        borderRadius: '8px',
        paddingRight: '40px', 
        position: 'relative', 
        '&:hover': {
          backgroundColor: '#f0f0f0', 
          color: 'black', 
        },
      }}
    >
      {selectedFile ? trimFileName(selectedFile.name) : 'Upload File'}
      {selectedFile && (
        <span
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setSelectedFile(null); // Clear the selected file
            setBase64Data(''); // Clear the base64 data
            if (fileInputRef.current) {
              fileInputRef.current.value = ""; // Clear the file input
            }
          }}
          style={{
            position: 'absolute',
            top: '-10px', // Position above the border
            right: '-10px', // Position outside the border
            backgroundColor: '#ff6666',
            borderRadius: '50%',
            width: '20px',
            height: '20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'white',
            fontSize: '12px',
            cursor: 'pointer',
            boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)',
          }}
        >
          &times; {/* Cross Icon */}
        </span>
      )}
    </Button>
  </label>
</div>

          <div className="submit-button">
            {/* <Button
              className="btn btn-primary mt-4"
              style={{
                display: "block",
                margin: "auto",
                width: "6rem",
                color: "black",
                backgroundColor: "#6acaffab",
              }}
              type="submit"
              variant="contained"
            >
              Submit
            </Button> */}
                            <Button
                  style={{
                    display: "block",
                    margin: "auto",
                    width: "7rem",
                    color: "white",
                    backgroundColor: "#3b6da7",
                  }}
                  type="submit"
                  variant="contained"
                  disabled={loading} // Disable button when loading
                >
                  {loading ? (
                    <div className="spinner-border text-light" role="status" style={{ fontSize: "1.2rem" }}>
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    "Submit"
                  )}
                </Button>

          </div>
        </form>
      </div>

      <div className="form-right">
        <h2 className="connected">Let's get Connected!</h2>
        <p className="info">
          We provide exceptional service, with a focus on building long-term
          relationships and proactively addressing staffing needs.
        </p>
        <h5 className="contact-details">Contact Details</h5>
        <div className="contact-container">
          <div className="contact">
            <div className="icon-container">
              <i className="fa fa-phone"></i>
            </div>
            <div className="icon-text">+1-214-382-6161</div>
          </div>

          <div className="contact">
            <div className="icon-container">
              <i className="fa fa-envelope"></i>
            </div>
            <div className="icon-text">info@neutrosys.com</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormEl;
