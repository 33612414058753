
import React, { useState, useRef,useEffect } from "react";
// import "../contactUs.css";
// import "./GetAQuote.css";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Modal from 'react-bootstrap/Modal';
import softwaredata from './softwaredata';
import './softwaretesting.css'
import "./form.css";
import ReCAPTCHA from "react-google-recaptcha";
import { TextField, Button, ButtonGroup,Box,Typography } from "@mui/material";
// import { useEffect } from "react";
import { Container } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { Multiselect } from "multiselect-react-dropdown"; //multiselect Library
import { ErrorMessage } from "@hookform/error-message";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import checkboxClasses from "@mui/material/Checkbox";
import { green } from "@mui/material/colors";
import { style } from "@mui/system";
import { pink } from "@mui/material/colors";
// import Switch from '@mui/material/Switch';
import Submission from "./Submission";
import swal from "sweetalert";
import axios from "axios";
import { useDropzone } from "react-dropzone";
import {
  CountryDropdown,
  RegionDropdown,
  CountryRegionData,
} from "react-country-region-selector";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
// import { Routes, Route, Link, Outlet } from "react-router-dom";

  function Softwaretesting() {

  const [lgShow, setLgShow] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [base64Data, setBase64Data] = useState("");
  const [loading, setLoading] = useState(false); 
  const [toastMessage, setToastMessage] = useState(""); 
   const [showToast, setShowToast] = useState(false); 
   const fileInputRef = useRef(null);
   
   const [componentKey, setComponentKey] = useState(0);
  


  const [options, setOptions] = useState([
    "iOS App Development",
    "Android App Development",
    "Web Development",
    "Enterprise Software Development",
    "Others",
  ]);

  const captchaRef = useRef(null);

  const {
    register,
    handleSubmit,
    reset,
    resetField,
    formState: { errors, isSubmitted },
    control,

  } = useForm();
  // const onSubmit = (data, e) => {
  //   console.log(data);
  //   reset();
  //   resetField();
  //   const token = captchaRef.current.getValue();
  //   captchaRef.current.reset();
  //   // swal("Form has been Submitted");
  //   toast.success("Success\n your form has been submitted");
  // };
  // const onSubmit = async (data, e) => {
  //   // setLoading(true);
  //   try{console.log('oko');
  
  //     // console.log(data);
  //     data.image = base64Data;
  //     // Fetch the base URL from the environment variable
  //     const baseURL = process.env.REACT_APP_API_URL;
  //     const responseforhost = await axios.post(
  //       `${baseURL}/api/mailsend/servicesgetaquotereceivedbyhost`,
  //       data
  //     );
  //     console.log(responseforhost);
  //     const responseforuser = await axios.post(
  //       `${baseURL}/api/mailsend/servicesquoterequestconfirmforuser`,
  //       data
  //     );
  //     console.log(responseforuser);
  //     const token = captchaRef.current.getValue();
  //     captchaRef.current.reset();
  //     toast.success("Success\n your form has been submitted");

  //   }
  //   catch (error) {
  //     console.error("Error submitting form:", error);
  //     toast.error("Submission failed. Please try again.");
  //   } finally {
  //     // setLoading(false); 
  //     reset();
      
  //     resetField();
  //     reset({ service: "" });
      
  //   }
    
  // };

  //Nirmal-1 test
  // const onSubmit = async (data) => {
  //   setLoading(true);
  //   try {
  //     // Capture the selected services
     
  //      // Add the selected services to the data object

  //     data.image = base64Data; // Add the base64 image data if any
  //     data.countryname = country; // Add country
  //     data.regionname = region; // Add region

  //     const baseURL = process.env.REACT_APP_API_URL;
  //     await axios.post(`${baseURL}/api/mailsend/servicesgetaquotereceivedbyhost`, data);
  //     await axios.post(`${baseURL}/api/mailsend/servicesquoterequestconfirmforuser`, data);

  //     const token = captchaRef.current.getValue();
  //     captchaRef.current.reset();
  //     toast.success("Success\n your form has been submitted");
  //   } catch (error) {
  //     console.error("Error submitting form:", error);
  //     toast.error("Submission failed. Please try again.");
  //   } finally {
  //     setLoading(false);
  //     reset({
  //       service: [], 
  //       name: "", 
  //       email: "",
  //       country_code: "",
  //       phone: "",
  //       location: "",
  //       subject: "",
  //       message: "",
  //     });
  //     reset();
  //     setCountry(""); // Reset country state
  //    setRegion(""); // Reset region state
  //    setBase64Data(""); // Reset base64 data if needed
  //   }
  // };
  const onSubmit = async (data, e) => {
    setLoading(true);
    try {
      console.log('oko');
      data.image = base64Data; 
      data.countryname = country; 
      data.regionname = region; 
      const baseURL = process.env.REACT_APP_API_URL;
  
      const responseforhost = await axios.post(
        `${baseURL}/api/mailsend/servicesgetaquotereceivedbyhost`,
        data
      );
      console.log(responseforhost);
      const responseforuser = await axios.post(
        `${baseURL}/api/mailsend/servicesquoterequestconfirmforuser`,
        data
      );
      console.log(responseforuser);
      const token = captchaRef.current.getValue();
      captchaRef.current.reset();
  
      // Set the toast message and show it
      setToastMessage("Success! Your form has been submitted.");
      setShowToast(true); // Trigger toast visibility
      
      setSelectedFile(null); // Clear the selected file state
      if (fileInputRef.current) {
        fileInputRef.current.value = ""; // Clear the file input
      }
  
      // Update component key to force re-render
      setComponentKey(prevKey => prevKey + 1); 
  
    } catch (error) {
      console.error("Error submitting form:", error);
      setToastMessage("Submission failed. Please try again.");
      setShowToast(true); // Trigger toast visibility
    } finally {
      setLoading(false);
      reset({
              service: [], 
              name: "", 
              email: "",
              country_code: "",
              phone: "",
              location: "",
              subject: "",
              message: "",
            });
            reset();
            setCountry(""); // Reset country state
           setRegion(""); // Reset region state
           setBase64Data(""); // Reset base64 data if needed
          }
        };

        const trimFileName = (fileName) => {
          if (fileName.length <= 15) {
            return fileName; // Return the original name if it's already short enough
          }
          return `${fileName.substring(0, 12)}...`; // Trim to 12 characters and add ellipsis
        };


useEffect(() => {
    if (showToast) {
      toast.success(toastMessage); 
      setShowToast(false); 
    }
  }, [showToast, toastMessage]);

  // const onDrop = (acceptedFiles) => {
  //   const file = acceptedFiles[0];
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       setBase64Data(reader.result);
  //     };
  //     reader.readAsDataURL(file);
  //   }
  // };
  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setBase64Data(reader.result);
        setSelectedFile(file); // Update the selected file state here
      };
      reader.readAsDataURL(file);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document', // Acceptable file types
  });

  const onCaptchaComplete = (response) => {
    console.log(response);
  };
  const optionSelect = [
    { value: "iOS App Development", label: "iOS App Development" },
    { value: "Android App Development", label: "Andriod App Development" },
    { value: "Web Development", label: "Web Development" },
    { value: "Enterprise Software Development", label: "Enterprise Software Development" },
    { value: "Others", label: "Others" },
   
    
  ];

  const [country, setCountry] = useState("");
  const [region, setRegion] = useState("");
  const selectCountry = (val) => setCountry(val);
  const selectRegion = (val) => {
    setRegion(val);
  };
  // const handleFileChange = (event) => {
  //   const file = event.target.files[0];
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       const base64 = reader.result; // Get the base64 data (omit the "data:image/jpeg;base64," prefix)
  //       console.log(base64);
  //       setBase64Data(base64); // Store the base64 data
  //     setSelectedFile(file); // Update the selected file state
        
  //     };
  //     reader.readAsDataURL(file);
  //     setSelectedFile(file);
  //   }
  // };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setBase64Data(reader.result); // Store the base64 data
        setSelectedFile(file); // Update the selected file state
      };
      reader.readAsDataURL(file);
    }
  };
  return (
    <div className="softwaretesting">
    <h4>Software Development</h4>
    
 
   <div className="softwaredatabox">
    {softwaredata.map((curElem)=>{
       return (
        <div className="dataitems" key={curElem.id}>
        <div className="images">
           <img src={curElem.image} alt=""  class="center"/>
        </div>
        
         <h5>{curElem.headings}</h5>
         <p>{curElem.discrip}</p>
        </div>
       )
    })}
   </div>
   <div className='free-quote-div'>
    <button className='freequote-btn' onClick={() => setLgShow(true)}>Get a FREE Quote </button> 
   </div>
    
   <Modal
        size="lg"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
        dialogClassName="modalbox-size"
      >
        <Modal.Header closeButton className="quote-header">
        <p className="heading-quote">Get a free quote?</p>
         </Modal.Header>
        <Modal.Body>
        <div  key={componentKey} className="services-form-container">
      {" "}
      <div className="mainForm getaquote-form">
        <ToastContainer position="top-center" />
        <form className="form_container p-0" onSubmit={handleSubmit(onSubmit)}>
          <Row
            className="form-row m-0"
            style={{ height: "auto", width: "auto" }}
          >
            <Col sm={12} lg={12} md={12} className="form-col getaquote-form-col">
            <Container className="formContainer">
            <Controller
        name="service"
        control={control}
        render={({ field }) => (
          <div>
            <Select
              isMulti
              {...field}
              options={optionSelect}
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder="Select Services"
              menuPortalTarget={document.body}
              styles={{
                placeholder: (base) => ({
                  ...base,
                  color:
                    isSubmitted && !field.value && errors?.service
                      ? "red"
                      : "gray",
                  textAlign: "left",
                }),
                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
              }}
            />
            {/* Error Message */}
            {errors?.service && (
              <p style={{ color: "red", marginTop: "5px" }}>
                {errors.service.message}
              </p>
            )}
          </div>
        )}
      />

{/* Use Controller for Select */}


                <TextField
                  // required

                  fullWidth
                  label="Full Name "
                  margin="dense"
                  name="name"
                  className="textfield"
                  variant="filled"
                  placeholder="Full Name"
                  // value={myForm.values.name}
                  // onChange={myForm.handleChange}
                  // error={!!myForm.errors.companyName}
                  // helperText={myForm.errors.name}
                  autoComplete="off"
                  sx={{
                    "& .MuiFilledInput-underline:before": {
                      borderBottom: "none",
                    },
                    "& .MuiFilledInput-underline:after": {
                      borderBottom: "none",
                    },
                    "& .MuiFilledInput-underline:hover:not(.Mui-disabled):before":
                      {
                        borderBottom: "none",
                      },
                  }}
                  {...register("name", { required: "Required", minLength: 3 })}
                  error={!!errors?.name}
                  // helperText={errors?.name ? errors.name.message : null}
                />

                <TextField
                  // required
                  fullWidth
                  label="Email"
                  margin="dense"
                  name="email"
                  className="textfield"
                  variant="filled"
                  placeholder="Email"
                  autoComplete="off"
                  sx={{
                    "& .MuiFilledInput-underline:before": {
                      borderBottom: "none",
                    },
                    "& .MuiFilledInput-underline:after": {
                      borderBottom: "none",
                    },
                    "& .MuiFilledInput-underline:hover:not(.Mui-disabled):before":
                      {
                        borderBottom: "none",
                      },
                  }}
                  {...register("email", {
                    required: "Required field",
                    pattern: {
                      value: /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i,
                      message: "Invalid email address",
                    },
                  })}
                  error={!!errors?.email}
                />
                <div className="tel">
                  <TextField
                    // fullWidth
                    placeholder="USA +1"
                    label="Country Code"
                    type="number"
                    margin="dense"
                    name="country_code"
                    autoComplete="off"
                    variant="filled"
                    sx={{
                      "& .MuiFilledInput-underline:before": {
                        borderBottom: "none",
                      },
                      "& .MuiFilledInput-underline:after": {
                        borderBottom: "none",
                      },
                      "& .MuiFilledInput-underline:hover:not(.Mui-disabled):before":
                        {
                          borderBottom: "none",
                        },
                    }}
                    className="txtfield_country_code"
                    {...register("country_code", { required: "Required" })}
                    error={!!errors?.country_code}
                  />
                  <TextField
                    // inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}

                    // fullWidth
                    
                    label="Phone"
                    margin="dense"
                    name="phone"
                    autoComplete="off"
                    variant="filled"
                    placeholder=" Landline/Mobile Number"
                    type="number"
                    className="txtfield_phone "
                    sx={{
                      "& .MuiFilledInput-underline:before": {
                        borderBottom: "none",
                      },
                      "& .MuiFilledInput-underline:after": {
                        borderBottom: "none",
                      },
                      "& .MuiFilledInput-underline:hover:not(.Mui-disabled):before":
                        {
                          borderBottom: "none",
                        },
                    }}
                    {...register("phone", { required: "Required" })}
                    error={!!errors?.phone}
                  />
                </div>
                <div className="country-region-services">
                <CountryDropdown
                      className="input-field select"
                      value={country}
                      style={{backgroundColor:"#e9ecef", borderRadius: "9px"}}
                      onChange={(val) => selectCountry(val)}
                    />
                <RegionDropdown
                      className="input-field select"
                      country={country}
                      value={region}
                    style={{backgroundColor:"#e9ecef", borderRadius: "9px"}}
                      blankOptionLabel="Select Region"
                      onChange={(val) => selectRegion(val)}
                    />
                    </div>
                <TextField
                  fullWidth
                  label="City"
                  margin="dense"
                  name="location"
                  autoComplete="off"
                  variant="filled"
                  placeholder="Your Location"
                  className="txtfield_country_code"
                  sx={{
                    "& .MuiFilledInput-underline:before": {
                      borderBottom: "none",
                    },
                    "& .MuiFilledInput-underline:after": {
                      borderBottom: "none",
                    },
                    "& .MuiFilledInput-underline:hover:not(.Mui-disabled):before":
                      {
                        borderBottom: "none",
                      },
                  }}
                  {...register("location", { required: "Required" })}
                  error={!!errors?.location}
                />
                <TextField
                  fullWidth
                  label="Subject"
                  margin="dense"
                  name="subject"
                  variant="filled"
                  placeholder="Subject"
                  className="txtfield_country_code"
                  // value={myForm.values.subject}
                  // onChange={myForm.handleChange}
                  // error={!!myForm.errors.companyName}
                  // helperText={myForm.errors.subject}

                  autoComplete="off"
                  sx={{
                    "& .MuiFilledInput-underline:before": {
                      borderBottom: "none",
                    },
                    "& .MuiFilledInput-underline:after": {
                      borderBottom: "none",
                    },
                    "& .MuiFilledInput-underline:hover:not(.Mui-disabled):before":
                      {
                        borderBottom: "none",
                      },
                  }}
                  {...register("subject", { required: "Required" })}
                  error={!!errors?.subject}
                />
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  label="Please tell us what you are looking for?"
                  margin="dense"
                  name="message"
                  variant="filled"
                  placeholder="Your Meassage"
                  className="txtfield_country_code message_box"
                  // value={myForm.values.message}
                  // onChange={myForm.handleChange}
                  // error={!!myForm.errors.companyName}
                  // helperText={myForm.errors.message}
                  autoComplete="off"
                  sx={{
                    "& .MuiFilledInput-underline:before": {
                      borderBottom: "none",
                    },
                    "& .MuiFilledInput-underline:after": {
                      borderBottom: "none",
                    },
                    "& .MuiFilledInput-underline:hover:not(.Mui-disabled):before":
                      {
                        borderBottom: "none",
                      },
                  }}
                  {...register("message", { required: "Required" })}
                  error={!!errors?.message}
                />
                     {/* <Box
  sx={{
    // border: '2px dashed #3b6da7',
    borderRadius: '8px',
    padding: '20px',
    textAlign: 'center',
    marginBottom: '1.5rem',
    backgroundColor: selectedFile ? '#e3f2fd' : '#fff',
    transition: 'background-color 0.3s',
  }}
>
  <input
    id="upload-file-input"
    type="file"
    accept=".jpg, .jpeg, .png , .pdf , .doc, .docx"
    onChange={handleFileChange}
    style={{ display: 'none' }} // Hide the default file input
  />
  <label htmlFor="upload-file-input">
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        cursor: 'pointer',
      }}
    >
      <Typography variant="body1" color="textSecondary">
        {selectedFile ? selectedFile.name : "Upload File"}
      </Typography>
      <Button
        variant="contained"
        component="span"
        sx={{
          marginTop: '10px',
          backgroundColor: '#3b6da7',
          color: 'white',
          '&:hover': {
            backgroundColor: '#2a5d8b',
          },
        }}
      >
        Upload File
      </Button>
    </Box>
  </label>
</Box> */}
 {/* <Box
                {...getRootProps()}
                sx={{
                  border: '2px dashed rgba(128, 128, 128, 0.521)',
                  borderRadius: '8px',
                  padding: '20px',
                  textAlign: 'center',
                  marginBottom: '1rem',
                  backgroundColor: base64Data ? '#e3f2fd' : '#fff',
                  cursor: 'pointer',
                  
                  '&:hover': {
                    borderColor: '#3b6da7',
                    backgroundColor: '#f0f8ff',
                  },
                }}
                className="responsive-upload-box"
              >
                <input {...getInputProps()} />
                <Typography variant="body2" color="textSecondary">
                  {base64Data ? 'File Uploaded' : 'Drag & Drop File Here or Click to Upload'}
                </Typography>
                <Button
                  variant="contained"
                  sx={{
                    marginTop: '10px',
                    backgroundColor: '#3b6da7',
                    color: 'white',
                    '&:hover': {
                      backgroundColor: '#2a5d8b',
                    },
                  }}
                  className="responsive-upload-button"
                >
                  Upload File
                </Button>
              </Box> */}
        {/* <Box
  {...getRootProps()}
  sx={{
    display: 'flex',
    alignItems: 'center',
    marginBottom: '1rem',
    marginTop:'7px',
    
    cursor: 'pointer',
  }}
  className="responsive-upload-box"
>
  <input {...getInputProps()} style={{ display: 'none' }} />
  <Button
    variant="contained"
    sx={{
      
      backgroundColor: selectedFile ? 'white' : '#3b6da7',
      color: 'black',
      '&:hover': {
        backgroundColor: '#2a5d8b',
      },
      padding: '5px 10px', // Adjust padding for a smaller button
      display: 'flex', // Ensure the button can accommodate text
      alignItems: 'center', // Center the text vertically
    }}
    className="responsive-upload-button"
  >
    {selectedFile ? trimFileName(selectedFile.name) : 'Upload File'}
  </Button>
</Box> */}
                  {/* <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: '1rem',
                      marginTop: '7px',
                    }}
                    className="responsive-upload-box"
                  >
                   
                    <input {...getInputProps()} style={{ display: 'none' }} />
                    <Button
                      variant="contained"
                      onClick={() => document.querySelector('input[type="file"]').click()} 
                      sx={{
                        backgroundColor: selectedFile ? 'white' : '#3b6da7',
                        color: 'black',
                        '&:hover': {
                          backgroundColor: '#2a5d8b',
                        },
                        padding: '5px 10px', 
                        display: 'flex', 
                        alignItems: 'center', 
                      }}
                      className="responsive-upload-button"
                    >
                      {selectedFile ? trimFileName(selectedFile.name) : 'Upload File'}
                    </Button>
                  </Box> */}

<div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
  <Box
    {...getRootProps()}
    sx={{
      display: 'inline-flex',
      alignItems: 'center', 
      marginBottom: '1rem',
      marginLeft: '0',
      marginTop: '7px',
      cursor: 'pointer',
    }}
    className="responsive-upload-box"
  >
    <input {...getInputProps()} style={{ display: 'none' }} />
    <Button
      variant="contained"
      sx={{
        backgroundColor: selectedFile ? 'white' : 'white',
        color: 'black',
        '&:hover': {
          backgroundColor: selectedFile ? 'white' : 'white',
          color: 'black',
        },
        padding: '5px 40px 5px 10px', // Increase right padding to create space for the close icon
        display: 'flex', 
        alignItems: 'center', 
        position: 'relative', // Set position to relative for the button
      }}
      className="responsive-upload-button"
    >
      {selectedFile ? trimFileName(selectedFile.name) : 'Upload File'}

      {selectedFile && ( 
        <span
          onClick={(event) => {
            event.stopPropagation();
            setSelectedFile(null); 
            setBase64Data(''); 
            if (fileInputRef.current) {
              fileInputRef.current.value = ""; 
            }
          }}
          style={{
            position: 'absolute',
            top: '-10px', // Position above the border
            right: '-10px', // Position outside the border
            backgroundColor: '#ff6666',
            borderRadius: '50%',
            width: '20px',
            height: '20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'white',
            fontSize: '12px',
            cursor: 'pointer',
            boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)',
          }}
        >
          &times; {/* Cross Icon */}
        </span>
      )}
    </Button>
  </Box>
</div>

                <div style={{ marginTop: 7, marginBottom: 8 }}>
                  <ReCAPTCHA
                    sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                    ref={captchaRef}
                    onChange={onCaptchaComplete}
                    // style={{ padding: 6 }}
                    className="my-recaptcha"
                  />
                </div>
                <div className="buttonsubmit quote-btn-submit">
                  {/* <Button
                    style={{
                      display: "block",
                      margin: "auto",
                      width: "7rem",
                      color: "white",
                      backgroundColor: "#3b6da7",
                    }}
                    // disabled={!myForm.isValid}
                    // onClick={myForm.submitForm}
                    type="submit"
                    variant="contained"
                  >
                    Submit
                  </Button> */}
                  <Button
                      style={{
                        display: "block",
                        margin: "auto",
                        width: "7rem",
                        color: "white",
                        backgroundColor: "#3b6da7",
                      }}
                      type="submit"
                      variant="contained"
                      // disabled={loading} // Disable button when loading
                      
                    >
                      {loading ? (
                        <div className="spinner-border text-light" role="status" style={{ fontSize: "1.2rem" }}>
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : (
                        "Submit"
                      )}
                    </Button>
                </div>
              </Container>
            </Col>
          </Row>
        </form>
      </div>
    </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default Softwaretesting